import React, { Component } from 'react'
import { Button, withStyles, ListItem, TextField } from '@material-ui/core'
import Theme from 'resources/Theme'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Text from './Text'
import Investing_Search from 'screens/Investing/Investing_Search'

class Header extends Component {

	state = {
		formData: {
			search: ""
		}
	}

	handleChange = (name, value) => {
		this.state.formData[name] = value

		this.setState(this.state)
	}

	render() {
		const { rightBar } = this.props
		const { formData } = this.state

		let pathname = _.split(this.props.location.pathname, '/')[2]

		return (
			<div style={{ width: 1000, margin: '20px auto' }}>
				<div style={{
					'letterSpacing': '0.054rem',
					'width': rightBar ? rightBar : 1000,
					'color': Theme.Colors.brownishGrey,
					'display': 'flex',
					'justifyContent': 'space-between'
				}}>
					{
						this.props.onBack ?
							<div style={{ display: 'flex', marginLeft: -15 }} className="pointer" onClick={() => this.props.history.goBack()}>
								<span className="material-icons" style={{ fontSize: 34, marginTop: 3 }}>chevron_left</span>
								<Text style={{ fontSize: 24 }}>{this.props.name || ""}</Text>
							</div>
							:
							<Text style={{ fontSize: 24 }}>{this.props.name || ""}</Text>
					}

					<div style={{ display: "flex", height: 45 }}>
						{
							this.props.invest_serach &&
							<Investing_Search />
						}

						{
							_.map([{
								route: "my-account", icon: "person"
							}, {
								route: "settings", icon: "settings"
							}], (value, index) => {
								return (
									<Button key={`button${index}`} style={{
										background: Theme.Colors.white,
										border: `1px solid ${Theme.Colors.lightGrey}`, marginLeft: 10,
										...pathname === value.route && { borderColor: Theme.Colors.primaryPurple }
									}} onClick={() => this.props.history.push(`/user/${value.route}`)}>
										<i className="material-icons" style={{
											color: pathname === value.route ? Theme.Colors.primaryPurple : Theme.Colors.warmGrey
										}}>{value.icon}</i>
									</Button>
								)
							})
						}

						{/* <Button style={{ background: Theme.Colors.white, border: `1px solid ${Theme.Colors.lightGrey}`, marginLeft: 10 }} onClick={() => this.props.history.push("/user/help")}>
							<i className="material-icons" style={{ color: Theme.Colors.warmGrey }}>help</i>
						</Button> */}
					</div>
				</div>
			</div>
		)
	}
}

const STYLES = {

}

export default withRouter(withStyles(STYLES)(Header))