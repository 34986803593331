export default {
	Colors: {
		primaryPurple: '#b04995', //Yellow
		purple2: '#b24696',
		powderPink: '#f4b5eb', //Green
		bubblegumPink: '#f594db',
		uglyPurple: '#b04995',

		paleMauve: '#ffe7f9',

		darkishGreen: '#2e9228',
		grassGreen: '#3d992e',

		orangeYellow: '#ffa000',

		coralRed: '#f04d4d',

		black: '#000000',
		black10: '#000000',

		purplishGrey: '#625f60',
		lightGrey: '#e0e0e0', //Light Grey
		lightGrey2: "#c4c4c4",
		warmGrey: '#9b9b9b',
		warmGreyTwo: '#838383',
		brownishGrey: "#696969",
		grey2: '#f4f4f4',
		white: '#ffffff',
		whiteTwo: '#f3f3f3',
		offWhite: '#fbfbfb',
		greyishBrown: '#4a4a4a',

		btnBgColor: "#B04995",
		btnBgColorHalf: "#D7A4CA",

		brown: '#211f20',

		Highlight1: '#7380BA', //Blue
		Highlight2: '#AB6DBA', //Purple

		Light2: '#E8E8E8', //Light Grey 2

		Transparent: 'transparent',

		TextPrimary: '#000000', //Black
		TextSecondary: '#95989A', //Med Grey
		TextTernary: '#343434',
		TextAccent: '#069FBD', //Green
	},
	FontFamily: {
		FONT1_REGULAR: 'Muli',
		FONT1_ITALIC: 'Calibri-LightItalic',
		FONT2_REGULAR: 'OcrA'
	},
	FontSize: {
		Overline: 10,
		Caption: 12,
		Body: 14,
		Subtitle: 16,
		H6: 20,
		H5: 24,
		H4: 34,
		H3: 48,
		H2: 60,
		H1: 95
	}
}