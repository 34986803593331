import React from "react"
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { withStyles } from "@material-ui/core/styles"
import HomeIcon from "images/icons/Sidebar-home-icon-active.svg"
import CardIcon from "images/icons/Sidebar-card-icon-active.svg"
import CashbackIcon from "images/icons/Sidebar-cashback-icon-active.svg"
import CurrencyProtectIcon from "images/icons/Sidebar-currency-protect-icon-active.svg"
import ExpensesIcon from "images/icons/Sidebar-expenses-icon-active.svg"
import PremiumIcon from "images/icons/Sidebar-premium-icon-active.svg"
import TransfersIcon from "images/icons/Sidebar-transfers-icon-active.svg"
import InvestIcon from "images/icons/InvestIcon.png"
import InvestIconInactive from "images/icons/InvestIconInactive.png"
import HomeIconInactive from "images/icons/Sidebar-home-icon-inactive.svg"
import CardIconInactive from "images/icons/Sidebar-card-icon-inactive.svg"
import CashbackIconInactive from "images/icons/Sidebar-cashback-icon-inactive.svg"
import CurrencyProtectIconInactive from "images/icons/Sidebar-currency-protect-icon-inactive.svg"
import ExpensesIconInactive from "images/icons/Sidebar-expenses-icon-inactive.svg"
import PremiumIconInactive from "images/icons/Sidebar-premium-icon-inactive.svg"
import TransfersIconInactive from "images/icons/Sidebar-transfers-icon-inactive.svg"
import _ from 'lodash'

import NorthLoopLogo from "images/logo.png"
import Theme from "resources/Theme"
import MyCard from "screens/Card/MyCard";
import Expense from "screens/Expense/Expense";
import TransferPay from "./TransferPay/TransferPay";
import Cashback from "./Cashback/Cashback";
import ComingSoon from "./ComingSoon";
import MyProfile from "./MyProfile/MyProfile";
import Transactions from "./Transactions/Transactions";
import CreditCardDetails from "./MyProfile/CreditCard/CreditCardDetails";
import MyAccount from "./MyAccount/MyAccount";
import AccountDetails from "./MyAccount/AccountDetails";
import ChangePassword from "./MyAccount/ChangePassword";
import ChangeAddress from "./MyAccount/ChangeAddress";
import LinkedAccounts from "./MyAccount/LinkedAccounts";
import ChangePhoneNumber from "./MyAccount/ChangePhoneNumber";
import Settings from "./MyAccount/Settings";
import AnalyzeSpends from "./Expense/AnalyzeSpends";
import ExpenseDetails from "./Expense/ExpenseDetails";
import ExpenseMerchantHistory from "./Expense/ExpenseMerchantHistory";
import Subscriptions from "./Expense/Subscriptions";
import { logout } from "actions/actions";
import US_CardDetails from "./Card/US/US_CardDetails";
import US_OrderCard from "./Card/US/US_OrderCard";
import US_LostCard from "./Card/US/US_LostCard";
import US_SetNewPin from "./Card/US/US_SetNewPin";
import NRI_FD_Create from "./NRI_Banking/FD/Create/NRI_FD_Create"
import NRI_FD_Detail from "./NRI_Banking/FD/NRI_FD_Detail"
import Investing from "./Investing/Investing";
import Investing_Order_History from "./Investing/Investing_Order_History";
import Investing_Holdings from "./Investing/Investing_Holdings";

const LogoContainer = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 64
}

const drawerWidth = 240

const STYLES = theme => ({
	root: {
		display: "flex",
	},
	drawer: {
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	active: {
		backgroundColor: Theme.Colors.btnBgColor + " !important",
		color: Theme.Colors.white
	},
	menuItem: {
		"&:hover": {
			backgroundColor: Theme.Colors.btnBgColorHalf + " !important",
			color: Theme.Colors.white,
			opacity: 0.5
		},
		marginTop: 20,
		borderRadius: 5
	},
	toolbar: theme.mixins.toolbar,
	rootDrawer: {
		width: 340
	},
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		minHeight: '100%',
		width: '100%',
		minHeight: '100vh !important',
		backgroundColor: Theme.Colors.whiteTwo,
	},
	bodyContainer: {
		width: 1000,
		margin: '0 auto'
	}
})

const FetchMenuItemIcon = (menuItem, active) => {
	switch (menuItem) {
		case "Home":
			return active ? HomeIcon : HomeIconInactive
		case "Invest":
			return active ? InvestIcon : InvestIconInactive
		case "My Cards":
			return active ? CardIcon : CardIconInactive
		case "Expenses":
			return active ? ExpensesIcon : ExpensesIconInactive
		case "Transfer and pay":
			return active ? TransfersIcon : TransfersIconInactive
		case "Cashback":
			return active ? CashbackIcon : CashbackIconInactive
		case "Currency protect":
			return active ? CurrencyProtectIcon : CurrencyProtectIconInactive
		case "Premium":
			return active ? PremiumIcon : PremiumIconInactive
		default:
			return HomeIcon
	}
}

const MenuItems = [{
	name: "Home", route: "my-profile"
}, {
	name: "Invest", route: "invest", width: 20
}, {
	name: "My Cards", route: "my-cards", cipTagNeeded: true
}, {
	name: "Expenses", route: "expenses"
}, {
	name: "Transfer and pay", route: "transfer-and-pay"
}, {
	name: "Cashback", route: "cashback", cipTagNeeded: true
},
	// {
	//     name: "Premium", route: "transactions"
	// },
]

class SideBar extends React.Component {

	state = {
		mobileOpen: false
	}

	handleDrawerToggle = () => {
		this.setState({
			mobileOpen: !this.state.mobileOpen
		})
	}

	drawer = (pathname) => {

		const { classes, userInfo } = this.props
		return (
			<div>
				<div className={classes.toolbar}>
					<div style={LogoContainer}>
						<img src={NorthLoopLogo} style={{ width: 150, marginTop: 20 }} />
					</div>
				</div>
				<List style={{ marginLeft: 20, marginRight: 20 }}>
					{MenuItems.map((value, index) => {
						if (value.cipTagNeeded && _.get(userInfo, 'cipTag') == "-1") {
							return null
						}

						return (
							<ListItem
								selected={pathname === value.route}
								onClick={() => { this.props.history.push(`/user/${value.route}`) }}
								button
								key={value.name}
								classes={{ root: classes.menuItem, selected: classes.active }}>
								<ListItemIcon>
									<img key={index} style={{ width: value.width || "auto", height: value.height || "auto" }} src={FetchMenuItemIcon(value.name, pathname === value.route)} />
								</ListItemIcon>
								<ListItemText style={{ marginLeft: -15 }} primary={value.name} />
							</ListItem>
						)
					})}
					<ListItem
						onClick={() => { this.props.logout("You have been logged out", this.props.history) }}
						button
						classes={{ root: classes.menuItem, selected: classes.active }}>
						<ListItemIcon>
							<img src={require("images/MyAccount/icon6.png")} style={{ width: 24 }} />
						</ListItemIcon>
						<ListItemText style={{ marginLeft: -15 }} primary="Logout" />
					</ListItem>
				</List>
			</div>
		)
	}

	render() {
		const { mobileOpen } = this.state
		const { container, userInfo, classes } = this.props

		let pathname = _.split(this.props.location.pathname, '/')[2]

		return (
			<div className={classes.root}>
				<nav className={classes.drawer} aria-label="mailbox folders">
					<Hidden smUp implementation="css">
						<Drawer
							container={container}
							variant="temporary"
							anchor={"left"}
							open={mobileOpen}
							onClose={this.handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper,
							}}
							classes={{
								root: classes.rootDrawer
							}}
							ModalProps={{ keepMounted: true }}>
							{this.drawer(pathname)}
						</Drawer>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Drawer
							classes={{
								paper: classes.drawerPaper,
							}}
							variant="permanent"
							open>
							{this.drawer(pathname)}
						</Drawer>
					</Hidden>
				</nav>
				<main className={classes.content}>
					<div className={classes.bodyContainer}>
						{
							!_.isEmpty(userInfo) &&
							<Switch>
								<Route path="/user/my-profile" exact component={MyProfile} />
								<Route path="/user/transactions/:account_id" exact component={Transactions} />
								<Route path="/user/credit-card/:account_id" exact component={CreditCardDetails} />

								{/* Invest */}
								<Route path="/user/invest" component={Investing} />

								{/* My Cards */}
								{
									_.get(userInfo, 'cipTag') !== -1 &&
									<Route path="/user/my-cards" exact component={MyCard} />
								}

								{
									_.get(userInfo, 'sign_up_status_2') === 'completed' &&

									<Route path="/user/my-cards/north-loop-us-debit/order-card" exact component={US_OrderCard} />
								}

								{
									_.get(userInfo, 'sign_up_status_2') === 'completed' &&
									<Route path="/user/my-cards/north-loop-us-debit/details" exact component={US_CardDetails} />
								}

								{
									_.get(userInfo, 'sign_up_status_2') === 'completed' &&
									<Route path="/user/my-cards/north-loop-us-debit/lost-card" exact component={US_LostCard} />
								}

								{
									_.get(userInfo, 'sign_up_status_2') === 'completed' &&
									<Route path="/user/my-cards/north-loop-us-debit/set-pin" exact component={US_SetNewPin} />
								}

								{/* Create FD */}
								<Route path="/user/india-bank/create-fd" exact component={NRI_FD_Create} />
								<Route path="/user/india-bank/fd-detail" exact component={NRI_FD_Detail} />

								<Route path="/user/expenses" exact component={Expense} />
								<Route path="/user/expense-details/:category/:month/:year" exact component={ExpenseDetails} />
								<Route path="/user/analyze-spends" exact component={AnalyzeSpends} />
								<Route path="/user/merchange-history" exact component={ExpenseMerchantHistory} />
								<Route path="/user/subscriptions" exact component={Subscriptions} />

								<Route path="/user/transfer-and-pay" exact render={props => <ComingSoon title="Transfer and Pay" />} />

								<Route path="/user/cashback" exact render={props => <ComingSoon title="Cashback" />} />

								<Route path="/user/settings" exact component={Settings} />
								<Route path="/user/my-account" exact component={MyAccount} />
								<Route path="/user/account-details" exact component={AccountDetails} />
								<Route path="/user/linked-accounts" exact component={LinkedAccounts} />
								<Route path="/user/orders" exact component={Investing_Order_History} />
								<Route path="/user/investments" exact component={Investing_Holdings} />
								<Route path="/user/change-address" exact component={ChangeAddress} />
								<Route path="/user/change-password" exact component={ChangePassword} />
								<Route path="/user/change-phone-number" exact component={ChangePhoneNumber} />

								<Route path="/user/help" exact render={props => <ComingSoon title="Help" />} />

								<Redirect to="/user/my-profile" />
							</Switch>
						}
					</div>
				</main>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo']),
})

const mapDispatchToProps = dispatch => ({
	logout: (message, history) => dispatch(logout(message, history))
})


export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SideBar))